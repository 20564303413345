<template>
  <div v-if="isShow" class="">
    <div v-if="showSlider" class="">
      <HomeSliderSection
        :title="title"
        :restaurants="restaurants"
        :is-loading="isLoading"
        :icon="pinImage"
        :see-more-link="''"
        :show-see-more="false"
        :side-image="false"
        :slide-per-view="slidePerView"
        @on-card-clicked="onCardClicked"
      >
      </HomeSliderSection>
    </div>
    <RequestLocation
      v-else
      :permission="permissionState || 'prompt'"
      @on-allow="requestUserLocation"
      @on-request-user-location="onRequestUserLocation"
    />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import {
  type PropType,
  ref,
  watch,
  defineAsyncComponent,
  computed,
  shallowRef,
} from "vue";
import type {
  RestaurantCardSliderProps,
  RestaurantCardProps,
} from "~/types/Restaurant";
import type { HomepageModelEvent } from "~/types/Home";
import pinImage from "~/assets/image/pin.png";
import { restaurantModelCardMapper } from "~/services/restaurant";
import useUserStore from "~/stores/user";
import useCityStore from "~/stores/city";

const RequestLocation = defineAsyncComponent(
  () => import("./RequestLocation.vue")
);
const props = defineProps({
  slidePerView: {
    type: [Number, String],
    required: true,
  },
  permissionState: {
    type: String as PropType<PermissionState>,
    default: "",
  },
});
const emits = defineEmits<{
  (e: "on-request-user-location"): void;
  (
    e: "on-click-restaurant",
    data: { card: RestaurantCardProps; section: HomepageModelEvent }
  ): void;
  (
    e: "on-click-featured-restaurant",
    data: { card: RestaurantCardProps; section: HomepageModelEvent }
  ): void;
}>();
const { t } = useI18n();
const userStore = useUserStore();
const { lat, lng } = storeToRefs(userStore);
const cityStore = useCityStore();
const { selectedCityId } = storeToRefs(cityStore);
const isShow = ref(true);
const isLoading = ref(false);
const title = t("nearMeRestaurant");
const restaurants = shallowRef<RestaurantCardSliderProps["restaurants"]>([]);
const HomeSliderSection = defineAsyncComponent(
  () => import("~/partial/home/HomeSliderSection.vue")
);
const showSlider = computed(() => {
  if (props.permissionState && props.permissionState !== "granted") {
    return false;
  }
  if (isLoading.value) {
    return true;
  }
  return restaurants.value.length > 0;
});

async function getNearMeRestaurant() {
  if (!lat.value && !lng.value) {
    return;
  }
  isLoading.value = true;
  const { searchRestaurant } = await import(
    "~/services/restaurant/searchRestaurant"
  );

  const { success, data } = await searchRestaurant({
    cityId: selectedCityId.value,
    location: {
      lat: lat.value,
      lng: lng.value,
    },
    pageNumber: 1,
    pageSize: 10,
  });
  if (success && data) {
    if (!data.data) {
      isShow.value = false;
      return;
    }
    restaurants.value = data.data.map((restaurant) =>
      restaurantModelCardMapper(restaurant)
    );
    isLoading.value = false;
  }
}

async function requestUserLocation() {}

function onRequestUserLocation() {
  emits("on-request-user-location");
}

function onCardClicked({
  card,
  position,
}: {
  card: RestaurantCardProps;
  position: number | string;
}) {
  const restaurantData = {
    card,
    section: {
      sectionName: title,
      sectionTemplate: "near_me",
      position,
    },
  };
  if (card.isFeatured) {
    emits("on-click-featured-restaurant", restaurantData);
  } else {
    emits("on-click-restaurant", restaurantData);
  }
}

watch(
  lat,
  () => {
    getNearMeRestaurant();
  },
  { immediate: true }
);
</script>
<script lang="ts">
export default {
  name: "NearMe",
};
</script>

<i18n lang="json">
{
  "en": {
    "nearMeRestaurant": "Near Me"
  },
  "th": {
    "nearMeRestaurant": "ร้านอาหารใกล้ฉัน"
  }
}
</i18n>
